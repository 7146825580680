/* Query Keys */
export enum QUERY_KEYS {
  EBILL_ELIGIBILITY_KEY = 'GET_EBILL_ELIGIBILITY',
  HSA_ELIGIBILITY_KEY = 'GET_HSA_ELIGIBILITY',
  BACKGROUND_INFORMATION = 'background_information',
  DEMOGRAPHICS_PROFILE = 'demographics_profile',
}

export const DEFAULT_PARTNER_ID = 'highmark';
export const AUTH0_CLIENT_CONNECTION = 'highmark';

/* Nav Bar */
export const APP_NAV_DEFAULT_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid
export const APP_NAV_MOBILE_HEIGHT = APP_NAV_DEFAULT_HEIGHT * 2; // Mobile Nav has 2 rows, so twice the default height

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'highmark-solutions',
};

export const AUTH0_ERROR_TYPES = {
  LOGIN_REQUIRED: 'login_required',
};

// Feature Flag Names
export enum FEATURE_FLAGS {
  PLACEHOLDER_FLAG = 'do_not_use', // Do not remove this non-functional flag: keep feature flag logic present even when we have 0 active flags
  /* Add active flags below this line */
  CLAIMS_SHOULD_SHOW_CLAIM_DETAILS = 'rel_claims_shouldShowClaimDetails',
  CLAIMS_SHOULD_SHOW_DOWNLOAD_CLAIMS = 'rel_claims_shouldShowDownloadClaims',
  CLAIMS_SHOULD_SHOW_FILTER_CHIPS = 'rel_claims_shouldShowFilterChips',
  CLAIMS_ENABLE_DYNAMIC_FILTERS = 'release_claims_configurable_filters',
  USER_DROPDOWN_COBROWSE = 'rel_hmrk_enableGlia',
  CMS_CODE = 'bus_hmrk_cmsCode',
  FORMS_LIBRARY = 'rel_hmrk_showFormsLibrary',
  COB_SDK = 'rel_hmrk_cobSDK',
  BACKGROUND_INFORMATION = 'rel_hmrk_backgroundInfo',
  WALLET_ENABLE_NO_NETWORK_LIVE_BALANCES = 'release_wallet_no_network',
  ENABLE_GENESIS_CORE_INSPECTOR = 'rel_hmrk_enableGenesisInspector',
  LIVE_CHAT_NAVIGATE_AWAY = 'experiment_live_chat_navigate_away',
  PWP = 'rel_hmrk_pwp',
}

export type FirebaseRemoteConfigValue = boolean | string | number;

export const MASONRY = {
  API_VERSION: 2,
  APP_ID_HOME_PAGE: 'hm_homepage',
  APP_ID_SUPPORT_PAGE: 'hm_support',
  APP_ID_CARE_DISCOVERY_HOME: 'hm_care_homepage',
  APP_ID_PWP_MAIN_PAGE: 'highmark_pwp',
  MODULE_KEY_CUSTOM_WIDGETS: 'highmark',
  MODULE_KEY_CLAIMS: 'claims',
  MODULE_KEY_HEALTH_JOURNEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_EBILL: 'hm_ebill',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_PENDING_HSA: 'hm_pending_hsa_banner',
  CUSTOM_WIDGET_TYPE_PRE_EFFECTUATED_BANNER: 'hm_pre_effectuated_banner',
  CUSTOM_WIDGET_TYPE_PRE_EFFECTIVE_BANNER: 'hm_pre_effective_banner',
  CUSTOM_WIDGET_TYPE_PWP_HEADER: 'pwp_header',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  CUSTOM_WIDGET_REWARD_PROGRAM_CAROUSEL: 'reward_programs_carousel',
  CUSTOM_WIDGET_REGION_BANNER: 'hm_region_banner',
  CUSTOM_WIDGET_TYPE_ACTIVITY_STACK: 'activity_stack',
  CUSTOM_WIDGET_TYPE_PWP_DISCLAIMER: 'pwp_disclaimer',
  NAMESPACE_HIGHMARK: 'highmark',
  NODE_TYPE_OVERVIEW_TEXT: 'overviewText',
  NODE_TYPE_BUTTON_SECONDARY: 'buttonSecondary',
};

export const LEGAL_DETAILS_QUERY_PARAM_KEY = 'id';

export const STORED_DEEPLINK_SESSION_STORAGE_KEY = 'stored_deeplink_url';

export const THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS = {
  YMCA: 'https://www.ymca.org/what-we-do/healthy-living/fitness/diabetes-prevention#locations',
  LIVONGO: 'https://welcome.livongo.com/DPPHM?ccid=DPPWM#/',
};

export const ANALYTICS_SCREEN_NAMES = {
  PWP: 'personalized wellness plan',
};

export const DESKTOP_SINGLE_COLUMN_CONTENT_WIDTH = '920px';
export const MAX_DISPLAY_MESSAGES_COUNT = 9; // To indicate there are more messages than the specified limit.
